import fEvents, { IEvent } from '../../js/events';
import { getSelectedMemberIdFromUrl, requestHandler, showMessage } from '../../js/tools';
import '../tooltip/tooltip';

export interface IPublicUserChangedEvent extends Event {
    newUserId: string,
    isAdmin: boolean
}

declare global {
    interface Window { _brandId: string; _localeMessage: any; _brandName: string }
}

window._brandId = window._brandId || '';
window._brandName = window._brandName || '';
window._localeMessage = window._localeMessage || '';

export default class HeaderBar extends HTMLElement {
    private readonly _memberId;
    private _members;
    private _brandId = window._brandId;
    constructor() {
        super();

        const shadowRoot = this.attachShadow({ mode: 'open' });

        this._memberId = this.getAttribute('data-member-id') || getSelectedMemberIdFromUrl();
        this._members = [];

        shadowRoot.innerHTML = this.render();

        let prevScrollTop = 0;
        window.addEventListener('scroll', () => {
            const isScrollingDown = (scrollTop() - prevScrollTop) > 0;
            this.classList[scrollTop() ? 'add' : 'remove']('elevated');
            this.classList[!!isScrollingDown && scrollTop() > 128 ? 'add' : 'remove']('closed');
            prevScrollTop = scrollTop();
        });

        fEvents.attachEvent('.custom-select-wrapper', 'click', this.onDropDownClick, this.shadowRoot);
        fEvents.attachEvent('.selected-member .reconnect-button', 'click', () => this.goToReconnectScreen(this.memberId), this.shadowRoot);
        fEvents.attachEvent('.hidden-click-observer', 'click', this.closeDropdownOnOutsideClick);
        fEvents.attachEvent('.custom-option', 'click', this.onMemberChanged, this.shadowRoot);

        function scrollTop() {
            return (document.body.parentElement) ? document.body.parentElement.scrollTop : 0;
        }
    }

    connectedCallback() {
        Promise.all([this.loadMembersToSelect()])
            .then(() => this.renderMembersSelect())
            .catch(err => {
                console.error(`Can't load the menu items due to an error: ${err.message}`);
            })
        ;
    }

    attributeChangedCallback(attrName: string, oldVal: string, newVal: string): void {
        switch (attrName) {
            case 'elevated' :
                this.classList[newVal ? 'add' : 'remove']('elevated');
                break;
        }
    }

    static get observedAttributes(): string[] {
        return ['elevated'];
    }

    get members() {
        return this._members;
    }

    get memberId(): string {
        const isObjectId = this._memberId.match(/^[0-9a-fA-F]{24}$/);
        if (!isObjectId) {
            return this._members[0]?._id || '';
        }
        return this._memberId;
    }

    loadMembersToSelect = async (): Promise<void> => {
        // Todo: move this backend requests out of the component when touching this code
        const { data: rawData } = await requestHandler({
            url: '/v2/admin-chatbox/members',
            disableNotification: true
        });
        this._members = JSON.parse(rawData);
    };

    onMemberChanged = (evt: IEvent<any>): void => {
        const selectedOption = evt?.target?.dataset?.value;
        if (!selectedOption) {
            console.error('The member you selected does not exist');
            return;
        }
        const newUserId = selectedOption;
        const currentUrl = window.location.href;
        const updatedUrl = this.updateUrlWithNewObjectId(currentUrl, newUserId);
        if (updatedUrl !== currentUrl) {
            window.location.href = updatedUrl.split('?')[0];
        }

        fEvents.dispatch(this, 'member-changed', { id: selectedOption });
    };

    renderMembersSelect = (): void => {
        const members = this._members.filter(m => !!m); // no null values
        const menuContainer = (this.shadowRoot.querySelector('.menu-container') as HTMLElement);

        if (!members || !members.length) {
            console.log('no members');
            menuContainer.innerHTML = this.renderMenuItems();
            return;
        }

        menuContainer.style.display = 'flex';
        const menuItems = this.renderMenuItems();
        const dropdown = this.renderMembersSelectDropdown(members);
        menuContainer.innerHTML = menuItems + dropdown;
    };

    goToReconnectScreen = (memberId: string): void => {
        const url = `/v2/settings/${memberId}?tab=profile&action=connect`;
        window.location.href = url;
    };

    // sending events
    extractObjectIdFromUrl = (url: string): string | null => {
        const match = url.match(/([a-f0-9]{24})(?:\?|$)/);
        return match ? match[1] : null;
    };

    updateUrlWithNewObjectId = (url: string, newObjectId: string): string => {
        const objectId = this.extractObjectIdFromUrl(url);
        if (!objectId) {
            console.error('The provided URL does not contain a valid objectId.');
            return url;
        }
        return url.replace(objectId, newObjectId);
    };

    renderMenuItems = (): string => {
        const selectedMember = this.members.find(m => m._id === this.memberId) || this.members[0];
        const currentUrl = window.location.pathname;

        if (!selectedMember) {
            return '';
        }

        let menuHTML = `
          <a href="/v2/postscheduler/${selectedMember._id}" class='${currentUrl.includes('postscheduler') ? 'active' : ''}'>
            <img alt="dashboard" src="/assets/images/dashboard-icon.svg" /> Dashboard
          </a>
        `;

        const isMessagingAllowed = selectedMember.allowMessaging;
        if (isMessagingAllowed) {
            menuHTML += `
          <a href="/v2/admin-chatbox/${selectedMember._id}" class='${currentUrl.includes('admin-chatbox') || currentUrl.includes('company') ? 'active' : ''}'>
            <i class="material-icons">chat</i> Messaging
          </a>
        `;
        }

        const shouldShowCampaigns = selectedMember.allowCampaigns;
        if (shouldShowCampaigns) {
            menuHTML += `
            <a href="/v2/campaigns/${selectedMember._id}" class='${currentUrl.includes('campaigns') ? 'active' : ''} campaign-header'>
              <i class="material-icons">campaign</i> Retargeting
            </a>
            <fl-tooltip placement="bottom" for=".campaign-header">Formerly Campaigns</fl-tooltip>
          `;
        }

        const shouldShowPosting = selectedMember.allowPosting;
        if (shouldShowPosting) {
            menuHTML += `
          <a href="/v2/posts/scheduling-by-id/${selectedMember._id}" class='${currentUrl.includes('scheduling') ? 'active' : ''}'>
            <i class="material-icons">schedule</i> Posting
          </a>
        `;
        }

        const shouldShowCommunity = selectedMember.allowCommunity;
        if (shouldShowCommunity) {
            menuHTML += `
          <a href="/v2/community/${selectedMember._id}?tab=posts" class='${currentUrl.includes('community') ? 'active' : ''}'>
            <i class="material-icons">group</i> Community
          </a>
        `;
        }

        menuHTML += `
          <a href="/v2/sequence-messages/settings/${selectedMember._id}?tab=schedule" class='settings ${currentUrl.includes('settings') ? 'active' : ''}'>
            <i class="material-icons">settings</i>
          </a>
        `;

        menuHTML += '<span class="divider"></span>';

        return menuHTML;
    };

    renderMembersSelectDropdown = (members): string => {
        const selectedMember = members.find(m => m._id === this.memberId) || members[0];
        const isAdmin = members.length > 1;

        if (!isAdmin) {
            return this.renderSelectedMember(selectedMember._id, selectedMember.name, selectedMember.connectionState);
        }

        return `
            <div class="custom-select-wrapper">
              <div class="custom-select">
                <div class="custom-select__trigger">
                    ${this.renderSelectedMember(selectedMember._id, selectedMember.name, selectedMember.connectionState)}
                    <div class="arrow"></div>
                </div>
                <div class="hidden-click-observer"></div> <!-- This is used to close the dropdown when clicking outside of it -->      
               
                <div class="custom-options">
                  ${members.filter(m => m._id !== selectedMember._id).map(member => this.renderMemberOption(member._id, member.name, member.connectionState, member._id === this.memberId)).join('')}
                </div>
              </div>
            </div>
        `;
    };

    renderSelectedMember = (_id:string, name:string, connectionState:string): string => {
        const renderReconnectButton = (_id, connectionState) => {
            switch (connectionState) {
                case 'disconnected':
                case 'off':
                    return `<button class="reconnect-button" data-id="${_id}" title="There's no connection to LinkedIn. Use this button to reactivate the connection">${this.getMemberSessionStateIcon(connectionState)}</button>`;
                case 'locked':
                    return `<button class="reconnect-button" data-id="${_id}" title="The account is being connected, press the button to reconnect manually">${this.getMemberSessionStateIcon(connectionState)}</button>`;
                case 'authenticating':
                case 'on':
                default:
                    return '';
            }
        };

        return `<div class="selected-member"><span class="selected-member-name">${name}</span>${renderReconnectButton(_id, connectionState)}</div>`;
    };

    renderMemberOption = (_id:string, name:string, connectionState:string, isSelected:boolean): string => {
        return `<span class="custom-option ${isSelected ? 'selected' : ''}" data-value="${_id}">${name} ${this.getMemberSessionStateIcon(connectionState)}</span>`;
    };

    getMemberSessionStateIcon = (connectionState: string): string => {
        switch (connectionState) {
            case 'authenticating':
            case 'on':
                return '';
            case 'disconnected':
            case 'off':
                return '<i class="material-icons connection-status-icon" title="This account is currently disconnected from LinkedIn, please reconnect by pressing this icon">link_off</i>'; // Make the yellow warning icon
            case 'locked':
                return '<i class="material-icons connection-status-icon icon-connecting" title="This account is currently connecting to LinkedIn, you can press this button to reconnect manually">rotate_right</i>'; // Make a loader
            default:
                return '';
        }
    };

    // Handle click events on the dropdown. Depending on the target, it will either open/close the dropdown or redirect to the reconnect screen
    onDropDownClick = (event: IEvent<any>) => {
        event.stopPropagation();
        const reconnectButton: HTMLButtonElement = this.shadowRoot.querySelector('.custom-select-wrapper .reconnect-button');

        if (reconnectButton && reconnectButton.contains(event.target as HTMLElement)) {
            const memberId = reconnectButton.dataset?.id;
            if (!memberId) {
                showMessage('The member you selected does not exist');
                return;
            }

            this.goToReconnectScreen(memberId);
            return;
        }

        const dropdown = this.shadowRoot.querySelector('.custom-select');
        dropdown.classList.toggle('open');
    };

    closeDropdownOnOutsideClick = (event: IEvent<any>) => {
        const dropdown = this.shadowRoot.querySelector('.custom-select');
        if (!dropdown) return;

        if (!dropdown.contains(event?.target)) {
            dropdown.classList.remove('open');
        }
    };

    render = (): string => `
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
        <link rel="stylesheet" href="/styles/styles.css" type="text/css">
        <link rel="stylesheet" href="/styles/index.css" type="text/css">
        <style>
            :host { background-color: #ffffff; height: 80px; border-bottom: 1px solid #E0E3EF; transition: box-shadow ease-out 0.2s, top ease-out 0.4s; position: fixed; left:0; top:0; right:0; z-index: 3; }
            :host(.elevated) { box-shadow: 0 8px 35px 0 rgba(0,0,0,0.12); }
            :host(.elevated.closed) { top: -80px; }
            .main-header { height: 100%; max-width: 1440px; margin: 0 auto; padding: 0 30px; display: flex; align-items: center; justify-content: space-between; font-size: 18px; }
            .menu-container { display: none; margin-top: 10px; align-items: center; justify-content: flex-end; }
            .menu-container a { font-size: 16px; color: #2B2F44; margin-right: 32px; font-weight: 500; border-bottom: 2px solid transparent; white-space: nowrap; }
            .menu-container a.active:not(.settings) { border-color: #2B2F44; }
            .menu-container a img { width: 22px; margin-right: 5px; vertical-align: middle; margin-bottom: 4px; }
            .menu-container a i { font-size: 18px; margin-right: 5px; vertical-align: middle; margin-bottom: 4px; }
            .logo { width: auto; height: 40px; object-fit: contain; }
            a { text-decoration: none; }
            .logo-menu { text-align: left; }
            .divider { width: 1px; height: 5em; margin-top: -18px; background-color: #E1E3EE; display: inline-block; }
            .selected-member { width: 300px; padding: 16px; display: flex; flex-direction: row; align-items: center; justify-content: space-between; }
            .reconnect-button { background-color: transparent; border: 0.5px solid #E1E3EE; border-radius: 8px; cursor: pointer; outline: none; display: flex; flex-direction: row; align-items: center; justify-content: center; height: 36px; width: 36px; }
            .custom-select-wrapper { position: relative; user-select: none; }
            .custom-select { position: relative; display: block; min-height: 60px; max-height: 60px; }
            .custom-select__trigger { position: relative; display: flex; flex-direction: row; align-items: center; justify-content: space-between; cursor: pointer; height: 60px; }
            .custom-select__trigger span { display: flex; flex-direction: row; flex: 1; justify-content: space-between; }
            .custom-options { position: absolute; display: none; top: 110%; left: -1px; right: 0; border: 1px solid #E1E3EE; border-top: none; background: #fff; z-index: 2; max-height: 372px; overflow: auto; }
            .custom-option { padding: 16px; cursor: pointer; line-break: strict; display: flex; flex-direction: row; align-items: center; justify-content: space-between; white-space: nowrap; min-height: 50px; max-height: 60px; max-width: 300px; }
            .custom-option:hover { background-color: #f2f2f2; }
            .custom-select.open .hidden-click-observer { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: transparent; z-index: 1; }
            .custom-select.open .custom-options { display: block; }
            .arrow { margin-left: auto; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 5px solid black; }
            @keyframes rotate-connecting-icon { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }
            .icon-connecting { animation: rotate-connecting-icon 2s linear infinite; }
               
        </style>
        <header class="main-header">
          <div class="logo-menu">
            <a href="#"><img alt="Logo" class="logo" src="/v2/backoffice/brands/${this._brandId}/logo"/></a>
          </div>

          <div class="service-menu">

            <div class="menu-container"></div>
          </div>
        </header>
    `;
}

customElements.define('header-bar', HeaderBar);
