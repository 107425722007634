import '../components/header-bar/header-bar';

(function (ref) {
    const container = document.querySelector(ref);
    const extensionId = container.querySelector('#extensionId').getAttribute('data-extension-id');
    const isChromium = window.chrome;

    if (!isChromium || chrome.runtime?.sendMessage === undefined) {
        return location.replace('/v2/auth/login');
    } else {
        chrome.runtime?.sendMessage(extensionId, { action: 'refresh-server-cookie' }, () => {
            return location.replace(`/v2/welcome?extensionId=${extensionId}`);
        });
    }

}('#main-layout'));

